import {
  GROUP_INFUSE_WITH_POWER,
  GROUP_INFUSE_WITH_POWER_2,
  GROUP_SPARK,
  ITEM_ENCHANTED_GILDED_HARBINGER_CREST,
  ITEM_ENCHANTED_RUNED_HARBINGER_CREST,
  ITEM_SPARK_OF_OMENS,
  ITEM_ENCHANTED_GILDED_UNDERMINE_CREST,
  ITEM_ENCHANTED_RUNED_UNDERMINE_CREST,
  ITEM_SPARK_OF_FORTUNES,
} from '@/utils/config/expansions/thewarwithin/reagents'

export default {
  [ITEM_ENCHANTED_RUNED_HARBINGER_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_OMENS],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_ENCHANTED_GILDED_HARBINGER_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_OMENS],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_ENCHANTED_RUNED_UNDERMINE_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_FORTUNES],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_ENCHANTED_GILDED_UNDERMINE_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_FORTUNES],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_SPARK_OF_OMENS]: {
    exclusiveIds: [
      ITEM_ENCHANTED_RUNED_HARBINGER_CREST,
      ITEM_ENCHANTED_GILDED_HARBINGER_CREST,
    ],
    groupIds: [
      GROUP_INFUSE_WITH_POWER,
      GROUP_INFUSE_WITH_POWER_2,
    ],
  },

  [ITEM_SPARK_OF_FORTUNES]: {
    exclusiveIds: [
      ITEM_ENCHANTED_RUNED_UNDERMINE_CREST,
      ITEM_ENCHANTED_GILDED_UNDERMINE_CREST,
    ],
    groupIds: [
      GROUP_INFUSE_WITH_POWER,
      GROUP_INFUSE_WITH_POWER_2,
    ],
  },
}
