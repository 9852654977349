import {
  ITEM_ENCHANTED_GILDED_HARBINGER_CREST,
  ITEM_ENCHANTED_RUNED_HARBINGER_CREST,
  ITEM_ENCHANTED_WEATHERED_HARBINGER_CREST,
  ITEM_SOUL_SIGIL_2,
  ITEM_SPARK_OF_OMENS,
  ITEM_ENCHANTED_GILDED_UNDERMINE_CREST,
  ITEM_ENCHANTED_RUNED_UNDERMINE_CREST,
  ITEM_ENCHANTED_WEATHERED_UNDERMINE_CREST,
  ITEM_SPARK_OF_FORTUNES,
} from '@/utils/config/expansions/thewarwithin/reagents'

export const TIER_3_ITEM_QUALITY_MODIFIER = 0.4

export const BASE_ITEM_LEVEL_MODIFIERS = {
  [ITEM_SPARK_OF_OMENS]: 593,
  [ITEM_SPARK_OF_FORTUNES]: 632,
}

export const ITEM_LEVEL_MODIFIERS = {
  [ITEM_SOUL_SIGIL_2]: { min: 561, max: 574 },
  [ITEM_ENCHANTED_WEATHERED_HARBINGER_CREST]: { min: 577, max: 590 },
  [ITEM_ENCHANTED_RUNED_HARBINGER_CREST]: { min: 606, max: 619 },
  [ITEM_ENCHANTED_GILDED_HARBINGER_CREST]: { min: 623, max: 636 },
  [ITEM_ENCHANTED_WEATHERED_UNDERMINE_CREST]: { min: 616, max: 629 },
  [ITEM_ENCHANTED_RUNED_UNDERMINE_CREST]: { min: 645, max: 658 },
  [ITEM_ENCHANTED_GILDED_UNDERMINE_CREST]: { min: 662, max: 675 },
}
